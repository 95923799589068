@font-face {
  font-family: "Nikkyou";
  src: url(~/src/fonts/NikkyouSans-mLKax.ttf);
}

h2 {
  font-size: 42px;
  font-weight: bolder;
}
@media screen and (max-width: 1023px) {
  h2 {
    font-size: 42px;
  }
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 20px;
  }
}

.App {
  text-align: center;
  font-family: "YuGothic", "Yu Gothic" sans-serif;
  background-color: #f2efe9;
}

.wrap {
}

.nikkyou {
  font-family: "Nikkyou";
}
.arrow-right {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  width: 1.5em;
  height: 0.1em;
  background: currentColor;
  margin-left: 2rem;
  color: #0074b6;
}
.arrow-right::before {
  content: "";
  width: 1em;
  height: 1em;
  border: 0.1em solid currentColor;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  transform-origin: top right;
  position: absolute;
  top: 50%;
  right: -0.05em;
  box-sizing: border-box;
}
.arrow-right::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  width: 3em;
  height: 3em;
  border: 0.1em solid currentColor;
  border-radius: 50%;
  box-sizing: content-box;
  top: -1.5rem;
  left: -0.75rem;
}
@media screen and (max-width: 767px) {
  .arrow-right {
    width: 0.75em;
    height: 0.125em;
  }
  .arrow-right::before {
    width: 0.5em;
    height: 0.5em;
    border: 0.15em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    right: -0.05em;
  }
  .arrow-right::after {
    width: 1.25em;
    height: 1.25em;
    border: 0.15em solid currentColor;
    top: -0.7rem;
    left: -0.35rem;
  }
}

.circle-top,
.circle-bottom {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.circle-top {
  background-image: url(../src/img/circle_top.svg);
  transform: scale(1);
}
.circle-bottom {
  background-image: url(../src/img/circle_bottom.svg);
  transform: scale(1);
}

.x-button-pc {
  display: block;
  position: relative;
  width: 75px;
  height: 75px;
  border: 3px solid #0074b6;
  background: #fff;
  border-radius: 0.5rem;
}
.x-button-pc::before,
.x-button-pc::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 50px;
  background: #0074b6;
  border-radius: 5px;
}
.x-button-pc::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.x-button-pc::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .x-button-pc {
    width: 30px;
    height: 30px;
  }
  .x-button-pc::before,
  .x-button-pc::after {
    width: 3px;
    height: 20px;
  }
}
.hamburger-menu {
  position: fixed;
  top: 16px;
  right: 5%;
  width: fit-content;
  height: fit-content;
  z-index: 10;
  background-color: white;
  padding: 0.2rem;
  border-radius: 6px;
}
.hamburger-menu span {
  width: 36px;
  height: 5px;
  border-radius: 5px;
  display: block;
  background-color: #f6c544;
  position: relative;
  transition: ease 0.4s; /*ハンバーガーメニュークリック時の三本線の動きを遅延*/
}
.hamburger-menu span:nth-child(1) {
  top: 0;
}
.hamburger-menu span:nth-child(2) {
  margin: 5px 0;
}
.hamburger-menu span:nth-child(3) {
  top: 0;
}
.navigation {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.75rem 2rem;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  gap: 1rem;
}

.navigation[aria-hidden="false"] {
  display: flex;
}

.info,
.sp-info {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  display: flex;
  border-radius: 0.75rem;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 135px;
}
.info-logo {
  height: 121px;
  --tw-translate-x: -0.625rem;
  --tw-translate-y: 2.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.info-title {
  position: absolute;
  --tw-translate-x: 90px;
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 19.7em;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
  font-size: 32px;
}
.info-date {
  --tw-bg-opacity: 1;
  background-color: rgb(242 238 233 / var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: 1.25rem;
  --tw-translate-y: 0.5rem;
  width: 235px;
  border-radius: 8px;
  font-size: 20px;
}
.info-more {
  padding-left: 2.5rem;
  --tw-translate-x: 0.25rem;
  --tw-translate-y: 2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media screen and (max-width: 1023px) {
  .info-title {
    --tw-translate-x: 60px;
    --tw-translate-y: 60px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: 50%;
    font-size: 24px;
  }
  .info-more {
    --tw-translate-x: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media screen and (max-width: 767px) {
  .info {
    height: 68px;
  }
  .info-logo {
    height: 65px;
    --tw-translate-y: 0.875rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .info-title {
    --tw-translate-x: 35px;
    --tw-translate-y: 30px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: 60%;
    font-size: 16px;
  }
  .info-date {
    position: absolute;
    --tw-translate-x: -7.25rem;
    --tw-translate-y: -2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: 200px;
    font-size: 16px;
    margin-right: 0;
  }
  .info-more {
    --tw-translate-x: -1.5rem;
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.sp-info {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  display: flex;
  border-radius: 0.75rem;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 100px;
}
.sp-info-logo {
  --tw-translate-x: -0.625rem;
  height: 65px;
  --tw-translate-y: 2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.sp-info-title {
  position: absolute;
  font-weight: 700;
  margin-right: auto;
  --tw-translate-x: 60px;
  --tw-translate-y: 35px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 70%;
  font-size: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sp-info-date {
  --tw-bg-opacity: 1;
  background-color: rgb(246 197 68 / var(--tw-bg-opacity));
  margin-left: auto;
  border-radius: 8px;
  text-align: center;
  --tw-translate-x: -0.5rem;
  --tw-translate-y: 0rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 200px;
  font-size: 16px;
  margin-right: 0;
}
.sp-info-more {
  padding-left: 2.5rem;
  --tw-translate-x: 3rem;
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media screen and (max-width: 1023px) {
  .sp-info-title {
    --tw-translate-y: 40px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    font-size: 24px;
    width: 65%;
  }
}
@media screen and (max-width: 767px) {
  .sp-info {
    height: 80px;
    --tw-translate-y: 35px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sp-info-title {
    --tw-translate-y: 40px;
    --tw-translate-x: 30px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    font-size: 16px;
    width: 75%;
    height: 3em;
  }
  .sp-info-date {
    position: absolute;
    width: 185px;
    color: #ffffff;
    --tw-translate-x: -105px;
    --tw-translate-y: -33px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sp-info-more {
    --tw-translate-y: 5px;
    --tw-translate-x: -30px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.whitespace-pre-wrap a {
  text-decoration: underline;
}
.hover:hover {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 200ms;
  --tw-translate-y: 0.125rem /* 2px */;
  --tw-translate-x: 0.125rem /* 2px */;
}
.shadow-medium {
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.25);
}
.balloon {
  display: inline-block;
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  border: 6px #0074b6 solid;
}
.balloon:before {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -30px;
  right: 65px;
  width: 0;
  height: 0;
  border-top: 40px solid #ffffff;
  border-right: 60px solid transparent;
  z-index: 20;
}
.balloon:after {
  content: "";
  position: absolute;
  margin: 0;
  bottom: -40px;
  right: 70px;
  width: 0;
  height: 0;
  border-top: 40px solid #0074b6;
  border-right: 60px solid transparent;
}
@media screen and (max-width: 767px) {
  .balloon {
    border: 4px #0074b6 solid;
  }
  .balloon:before {
    display: none;
  }
  .balloon:after {
    display: none;
  }
}
.text-shadow {
  text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);
}
